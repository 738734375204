<template>
  <div>
    <b-form-datepicker v-if="getVenue" :min="minDate" :max="maxDate" :locale="$i18n.locale" id="datePicker" v-model="value"></b-form-datepicker>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'DatePicker',
    created() {

    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)

      return {
        value: today,
        maxDate: maxDate
      }
    },
    computed: {
		...mapGetters(["getVenue"]),
    getVenueAttributes() {
      return this.getVenue.attributes || {}
    },
    minDate() {
      return new Date(this.getVenueAttributes.createdAt)
    },
    },
    watch: {
      value(newValue) {
        this.$emit('date-selected', newValue);
      },
    },
  }
</script>

<style scoped>
.winesViewHeader .datePicker {
  margin-left: auto;
  max-width: 200px;
}
</style>