<template>
  <b-dropdown-item-button class="print" @click="print()">{{ $t('general.buttons.print')}}</b-dropdown-item-button>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
<style scoped>

</style>